<template>
	<div class="h-100">
		<TypeOrderForm @cancel="goToList" @submit="createOrderType" />
	</div>
</template>

<script>
import { CREATE_ORDER_TYPE } from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
import { RESET_STATE } from "../store/mutation-types"
const { mapActions, mapMutations } = createNamespacedHelpers("typeOrder")

export default {
	name: "CreateType",
	components: {
		TypeOrderForm: () => import("../components/TypeOrderForm"),
	},
	beforeDestroy() {
		this.RESET_STATE()
	},
	methods: {
		...mapActions({ CREATE_ORDER_TYPE }),
		...mapMutations({ RESET_STATE }),
		createOrderType: async function (params) {
			this.showLoading()
			if (params.file) {
				// upload file
				const response = await this.$store.dispatch("uploadFile", params.file)

				if (response.ok) {
					delete params.file
					params["tokens"] = response.tokens
				}
			}

			// create type order
			await this.CREATE_ORDER_TYPE(params)
			this.hideLoading()
		},
		goToList() {
			this.$router.push({ name: "TypeOrderManagement" })
		},
	},
}
</script>
